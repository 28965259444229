import { memo } from 'react';
import { T } from '../../../translation/src';
import { Plane } from '../../../types/graphqlTypes';
import { PropertiesSection, Property } from './PropertiesSection';
import { PositionEditProperties } from './PositionEditProperties';
import { PlaneAnglesEditProperties } from './PlaneAngleEditProperties';

interface PlanePropertiesProps {
  annotation: Plane;
}
const $PlaneProperties: React.FC2<PlanePropertiesProps> = ({ annotation: plane }) => {
  return (
    <div className="flex flex-col space-y-4 divide-y divide-[#515256]">
      <PropertiesSection title={<T _str="information" swc />}>
        <Property title={<T _str="type" swc />}>
          <T _str="Plane" swc />
        </Property>
      </PropertiesSection>

      <PositionEditProperties identifier={plane.identifier} />
      <PlaneAnglesEditProperties identifier={plane.identifier}></PlaneAnglesEditProperties>
    </div>
  );
};
export const PlaneProperties = memo($PlaneProperties);
