import gql from 'graphql-tag';

export const PROJECTS_FRAGMENT = gql`
  fragment Projects on Project {
    id
    name
    description
    uploadStatus
    isInitializing
    assignee {
      id
      name
    }
    pointClouds {
      pointCount
    }
    previewUrl
  }
`;

export const PROJECT_STATE_FRAGMENT = gql`
  fragment ProjectState on ProjectState {
    annotations {
      identifier
      groupIdentifier
      type
      name
      visible
      hiddenLabels
      annotationColor {
        r
        g
        b
        a
      }
      ... on PointAnnotation {
        position {
          x
          y
          z
        }
        color {
          r
          g
          b
          a
        }
        pointCloudId
        classification
        intensity
      }
      ... on DistanceAnnotation {
        points {
          position {
            x
            y
            z
          }
          pointCloudId
        }
      }
      ... on AreaAnnotation {
        points {
          position {
            x
            y
            z
          }
          pointCloudId
        }
      }
      ... on BoxAnnotation {
        position {
          x
          y
          z
        }
        scale {
          x
          y
          z
        }
        rotation {
          x
          y
          z
        }
        annotationFilter {
          clipMethod
        }
      }
      ... on PointCluster {
        segments {
          pointcloudId
          segmentId
        }
        annotationFilter {
          clipMethod
        }
        annotationClass
      }
      ... on Plane {
        position {
          x
          y
          z
        }
        normal {
          x
          y
          z
        }
      }
    }
    groups {
      identifier
      name
    }
    orderedIdentifiers {
      identifier
      index
    }
    wmsLayerOrderedIdentifiers {
      identifier
      index
    }
    orthophotoLayerOrderedIdentifiers {
      identifier
      index
    }
    cadLayers {
      identifier
      name
      cadObjectGroups {
        identifier
        cadLayerId
        name
        cadObjects {
          identifier
          cadLayerId
          cadObjectGroupId
          type
          name
          color {
            r
            g
            b
            a
          }

          visible
          ... on CadPoint {
            position {
              x
              y
              z
            }
            __typename
          }
          ... on CadLine {
            pointsCompressed
            __typename
          }
          ... on CadPolygon {
            pointsCompressed
            __typename
          }
        }
      }
    }
    wmsLayers {
      identifier
      type
      name
      url
      username
      password
      layerName
      visible
    }
    orthophotoLayers {
      identifier
      name
      visible
      url
      elevation
    }
  }
`;

export const PROJECT_CALCULATION_FRAGMENT = gql`
  fragment Calculation on Calculation {
    id
    status
    annotationIdentifier
    pointCloudIdentifier
    ... on VolumeCalculation {
      baseSurface
      result {
        volume
        area
        volumePositive
        volumeNegative
      }
      paramInfo {
        referenceHeight
        heightFilters
        intensityFilters
        classificationFilters
        resolutionFilter
        clippingFilter
      }
      isOutDated
    }
    ... on ProjectionCalculation {
      profileGroupIdentifier
      result {
        projection {
          distance
          height
        }
      }
      paramInfo {
        heightFilters
        intensityFilters
        classificationFilters
        resolutionFilter
        clippingFilter
      }
      isOutDated
    }
  }
`;

export const PROJECT_EXPORT_FRAGMENT = gql`
  fragment Export on Export {
    status
    type
    paramInfo {
      fileType
      heightFilters
      classificationFilters
    }
  }
`;

export const PROJECT_FRAGMENT = gql`
  ${PROJECT_STATE_FRAGMENT}
  ${PROJECT_CALCULATION_FRAGMENT}
  ${PROJECT_EXPORT_FRAGMENT}
  fragment Project on Project {
    id
    name
    description
    uploadStatus
    assignee {
      id
      name
    }
    pointClouds {
      id
      hasClassifications
      uploadStatus
      cloudName
      availableClasses
      visible
      displayName
      pointCount
    }
    state {
      ...ProjectState
    }
    settings {
      projectionSystem
    }
    calculations {
      ...Calculation
    }
    exports {
      ...Export
    }
    mapVisible
    comments {
      id
      comment
      user {
        id
        name
      }
      referenceIdentifier
      createdAt
      updatedAt
    }
    customClasses {
      id
      name
      color {
        r
        g
        b
        a
      }
      code
    }
    previewUrl
  }
`;

export const PROJECTS = gql`
  ${PROJECTS_FRAGMENT}
  query projects($organisationId: ID!) {
    projectsByOrganisationId(organisationId: $organisationId) {
      ...Projects
    }
  }
`;

export const PROJECT = gql`
  ${PROJECT_FRAGMENT}
  query projectById($projectId: ID!) {
    projectById(projectId: $projectId) {
      ...Project
    }
  }
`;

export const PROJECT_CAD_LAYERS = gql`
  query projectCadLayers($projectId: ID!) {
    projectById(projectId: $projectId) {
      id
      state {
        cadLayers {
          identifier
          name
          cadObjectGroups {
            identifier
            cadLayerId
            name
            cadObjects {
              identifier
              cadLayerId
              cadObjectGroupId
              type
              name
              color {
                r
                g
                b
                a
              }
              visible
              ... on CadPoint {
                position {
                  x
                  y
                  z
                }
              }
              ... on CadLine {
                points {
                  x
                  y
                  z
                }
              }
              ... on CadPolygon {
                points {
                  x
                  y
                  z
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const PROJECT_CALCULATIONS = gql`
  ${PROJECT_CALCULATION_FRAGMENT}
  query projectCalculations($projectId: ID!) {
    projectById(projectId: $projectId) {
      id
      calculations {
        ...Calculation
      }
    }
  }
`;

export const PROJECT_EXPORTS = gql`
  ${PROJECT_EXPORT_FRAGMENT}
  query projectExports($projectId: ID!) {
    projectById(projectId: $projectId) {
      id
      exports {
        ...Export
      }
    }
  }
`;

export const REQUEST_UPLOAD_SAS = gql`
  query projectRequestUploadSAS($projectId: ID!) {
    projectRequestUploadSAS(projectId: $projectId)
  }
`;

export const REQUEST_READ_SAS_TOKEN = gql`
  query projectRequestReadSASToken($projectId: ID!) {
    projectRequestReadSASToken(projectId: $projectId)
  }
`;

export const PROJECT_START = gql`
  ${PROJECTS_FRAGMENT}
  mutation projectStart(
    $projectId: ID!
    $fileNames: [String!]!
    $organisationId: ID!
    $name: String!
    $description: String!
    $shouldMerge: Boolean
  ) {
    projectStart(
      projectId: $projectId
      fileNames: $fileNames
      organisationId: $organisationId
      name: $name
      description: $description
      shouldMerge: $shouldMerge
    ) {
      ...Projects
    }
  }
`;

export const PROJECT_RELAUNCH = gql`
  ${PROJECTS_FRAGMENT}
  mutation projectRelaunch($projectId: ID!) {
    projectRelaunch(projectId: $projectId) {
      ...Projects
    }
  }
`;

export const PROJECT_START_WITH_PYTHAGORAS_FILE = gql`
  ${PROJECTS_FRAGMENT}
  mutation projectStartWithPythagorasFiles(
    $projectId: ID!
    $cloudName: String!
    $organisationId: ID!
    $name: String!
    $description: String!
  ) {
    projectStartWithPythagorasFiles(
      projectId: $projectId
      cloudName: $cloudName
      organisationId: $organisationId
      name: $name
      description: $description
    ) {
      ...Projects
    }
  }
`;

export const PROJECT_UPDATE = gql`
  ${PROJECTS_FRAGMENT}
  mutation projectUpdate($projectId: ID!, $name: String!, $description: String!) {
    projectUpdate(projectId: $projectId, name: $name, description: $description) {
      ...Projects
    }
  }
`;

export const PROJECT_UPDATE_PREVIEW = gql`
  ${PROJECTS_FRAGMENT}
  mutation projectUpdatePreviewImage($projectId: ID!, $previewImageFileName: String!) {
    projectUpdatePreviewImage(projectId: $projectId, previewImageFileName: $previewImageFileName) {
      ...Projects
    }
  }
`;

export const PROJECT_UPDATE_SETTINGS = gql`
  mutation projectUpdateSettings($projectId: ID!, $settings: InputProjectSettings!) {
    projectUpdateSettings(projectId: $projectId, settings: $settings) {
      id
      settings {
        projectionSystem
      }
    }
  }
`;

export const PROJECT_DELETE = gql`
  mutation projectDelete($projectId: ID!) {
    projectDelete(projectId: $projectId)
  }
`;

export const PROJECT_ACTIVATE = gql`
  mutation projectActivate($projectId: ID!) {
    projectActivate(projectId: $projectId) {
      uploadStatus
    }
  }
`;

export const PROJECT_DUPLICATE = gql`
  mutation projectDuplicate($projectId: ID!, $name: String!, $description: String!) {
    projectDuplicate(projectId: $projectId, name: $name, description: $description) {
      id
    }
  }
`;

export const PROJECT_CONFIRM_UPLOAD = gql`
  mutation projectConfirmPointCloudUpload($projectId: ID!, $fileNames: [String!]!, $shouldMerge: Boolean) {
    projectConfirmPointCloudUpload(projectId: $projectId, fileNames: $fileNames, shouldMerge: $shouldMerge)
  }
`;

export const PROJECT_UNDO_ACTION = gql`
  ${PROJECT_STATE_FRAGMENT}
  mutation projectUndoAction($projectId: ID!, $identifier: String!) {
    projectUndoAction(projectId: $projectId, identifier: $identifier) {
      id
      state {
        ...ProjectState
      }
      calculations {
        ... on VolumeCalculation {
          isOutDated
        }
        ... on ProjectionCalculation {
          isOutDated
        }
      }
      pointClouds {
        id
        displayName
      }
    }
  }
`;

export const PROJECT_ADD_ACTION = gql`
  ${PROJECT_STATE_FRAGMENT}
  mutation projectAddAction($projectId: ID!, $action: InputProjectAction!) {
    projectAddAction(projectId: $projectId, action: $action) {
      id
      state {
        ...ProjectState
      }
      calculations {
        ... on VolumeCalculation {
          isOutDated
        }
        ... on ProjectionCalculation {
          isOutDated
        }
      }
      pointClouds {
        id
        displayName
      }
    }
  }
`;

export const PROJECT_TOGGLE_OBJECT_VISIBILITY = gql`
  ${PROJECT_STATE_FRAGMENT}
  mutation projectToggleObjectVisibility($projectId: ID!, $identifiers: [String!]!, $visible: Boolean!) {
    projectToggleObjectVisibility(projectId: $projectId, identifiers: $identifiers, visible: $visible) {
      id
      state {
        ...ProjectState
      }
      pointClouds {
        id
        visible
      }
      mapVisible
    }
  }
`;

export const PROJECT_TOGGLE_OBJECT_LABEL_VISIBILITY = gql`
  mutation projectToggleObjectLabelVisibility(
    $projectId: ID!
    $identifiers: [String!]!
    $visible: Boolean!
    $label: AnnotationLabels!
  ) {
    projectToggleObjectLabelVisibility(
      projectId: $projectId
      identifiers: $identifiers
      visible: $visible
      label: $label
    ) {
      id
      state {
        annotations {
          identifier
          hiddenLabels
        }
      }
    }
  }
`;

export const PROJECT_UPDATE_CLIPPING_METHOD = gql`
  mutation projectUpdateClippingMethod($projectId: ID!, $annotationIdentifiers: [String!]!, $clipMethod: ClipMethods!) {
    projectUpdateClippingMethod(
      projectId: $projectId
      annotationIdentifiers: $annotationIdentifiers
      clipMethod: $clipMethod
    ) {
      id
      state {
        annotations {
          ... on BoxAnnotation {
            annotationFilter {
              clipMethod
            }
          }
          ... on PointCluster {
            annotationFilter {
              clipMethod
            }
          }
        }
      }
    }
  }
`;

export const PROJECT_VOLUME_CALCULATE = gql`
  ${PROJECT_CALCULATION_FRAGMENT}
  mutation projectVolumeCalculate(
    $projectId: ID!
    $pointCloudId: ID!
    $annotationIdentifier: String!
    $referenceHeight: Float
    $baseSurface: CalculationBaseSurfaces!
    $classificationFilters: [Int!]
    $heightFilters: [Int]
    $intensityFilters: [Int]
    $resolutionFilter: Int
    $clippingFilter: Int
  ) {
    projectVolumeCalculate(
      projectId: $projectId
      pointCloudId: $pointCloudId
      annotationIdentifier: $annotationIdentifier
      referenceHeight: $referenceHeight
      baseSurface: $baseSurface
      classificationFilters: $classificationFilters
      heightFilters: $heightFilters
      intensityFilters: $intensityFilters
      resolutionFilter: $resolutionFilter
      clippingFilter: $clippingFilter
    ) {
      id
      calculations {
        ...Calculation
      }
    }
  }
`;

export const PROJECT_EXPORT_POINTCLOUD = gql`
  ${PROJECT_EXPORT_FRAGMENT}
  mutation projectExportPointcloud(
    $projectId: ID!
    $pointCloudId: ID!
    $fileType: FileTypes
    $heightFilters: [Int]
    $intensityFilters: [Int]
    $classificationFilters: [Int]
    $resolutionFilter: Int
    $clippingFilter: Int
  ) {
    projectExportPointcloud(
      projectId: $projectId
      pointCloudId: $pointCloudId
      fileType: $fileType
      heightFilters: $heightFilters
      intensityFilters: $intensityFilters
      classificationFilters: $classificationFilters
      resolutionFilter: $resolutionFilter
      clippingFilter: $clippingFilter
    ) {
      id
      exports {
        ...Export
      }
    }
  }
`;

export const PROJECT_PROJECTION_CALCULATE = gql`
  ${PROJECT_CALCULATION_FRAGMENT}
  mutation projectProjectionCalculate(
    $projectId: ID!
    $pointCloudId: ID!
    $annotationIdentifier: String!
    $profileGroupIdentifier: String!
    $classificationFilters: [Int!]
    $heightFilters: [Int]
    $intensityFilters: [Int]
    $resolutionFilter: Int
    $clippingFilter: Int
  ) {
    projectProjectionCalculate(
      projectId: $projectId
      pointCloudId: $pointCloudId
      annotationIdentifier: $annotationIdentifier
      profileGroupIdentifier: $profileGroupIdentifier
      classificationFilters: $classificationFilters
      heightFilters: $heightFilters
      intensityFilters: $intensityFilters
      resolutionFilter: $resolutionFilter
      clippingFilter: $clippingFilter
    ) {
      id
      calculations {
        ...Calculation
      }
    }
  }
`;

export const PROJECT_DELETE_CALCULATION = gql`
  ${PROJECT_CALCULATION_FRAGMENT}
  mutation projectDeleteCalculation($projectId: ID!, $calculationId: ID!) {
    projectDeleteCalculation(projectId: $projectId, calculationId: $calculationId) {
      id
      calculations {
        ...Calculation
      }
    }
  }
`;

export const PROJECT_GENERATE_CLASSIFICATIONS = gql`
  ${PROJECT_CALCULATION_FRAGMENT}
  mutation projectGenerateClassifications($projectId: ID!, $cloudName: String!) {
    projectGenerateClassifications(projectId: $projectId, cloudName: $cloudName) {
      id
      pointClouds {
        hasClassifications
      }
    }
  }
`;

export const POINT_CLOUD_DELETE = gql`
  ${PROJECT_FRAGMENT}
  mutation projectPointCloudDelete($projectId: ID!, $pointCloudId: ID!) {
    projectPointCloudDelete(projectId: $projectId, pointCloudId: $pointCloudId) {
      ...Project
    }
  }
`;

export const POINTCLOUD_UPLOAD_STATUS = gql`
  ${PROJECT_FRAGMENT}
  query pointCloudUploadStatus($projectId: ID!) {
    projectById(projectId: $projectId) {
      id
      pointClouds {
        id
        uploadStatus
      }
    }
  }
`;

export const PROJECT_INITIALIZE = gql`
  mutation projectInitialize($organisationId: ID!) {
    projectInitialize(organisationId: $organisationId) {
      id
    }
  }
`;

export const PROJECT_ADD_COMMENT = gql`
  mutation projectAddComment($projectId: ID!, $comment: String!, $referenceIdentifier: String!) {
    projectAddComment(projectId: $projectId, comment: $comment, referenceIdentifier: $referenceIdentifier) {
      comments {
        id
      }
    }
  }
`;

export const PROJECT_DELETE_COMMENT = gql`
  mutation projectDeleteComment($commentId: ID!, $projectId: ID!) {
    projectDeleteComment(commentId: $commentId, projectId: $projectId) {
      id
      comments {
        id
      }
    }
  }
`;

export const PROJECT_EDIT_COMMENT = gql`
  mutation projectEditComment($commentId: ID!, $projectId: ID!, $newComment: String!) {
    projectEditComment(commentId: $commentId, projectId: $projectId, updatedComment: $newComment) {
      id
      comments {
        id
        __typename
        comment
      }
    }
  }
`;

export const PROJECT_ADD_CUSTOM_CLASS = gql`
  mutation projectAddCustomClass($projectId: ID!, $name: String!, $color: InputColor!) {
    projectAddCustomClass(projectId: $projectId, name: $name, color: $color) {
      id
      customClasses {
        id
        name
        color {
          r
          g
          b
          a
        }
        code
      }
    }
  }
`;

export const PROJECT_DELETE_CUSTOM_CLASS = gql`
  mutation projectDeleteCustomClass($customClassId: ID!, $projectId: ID!) {
    projectDeleteCustomClass(customClassId: $customClassId, projectId: $projectId) {
      id
      state {
        annotations {
          ... on PointCluster {
            annotationClass
          }
        }
      }
      customClasses {
        id
      }
    }
  }
`;

export const PROJECT_EDIT_CUSTOM_CLASS_NAME = gql`
  mutation projectEditCustomClassName($customClassId: ID!, $projectId: ID!, $newName: String!) {
    projectEditCustomClassName(customClassId: $customClassId, projectId: $projectId, updatedName: $newName) {
      id
      customClasses {
        id
        name
      }
    }
  }
`;

export const PROJECT_EDIT_CUSTOM_CLASS_COLOR = gql`
  mutation projectEditCustomClassColor($customClassId: ID!, $projectId: ID!, $newColor: InputColor!) {
    projectEditCustomClassColor(customClassId: $customClassId, projectId: $projectId, updatedColor: $newColor) {
      id
      customClasses {
        id
        color {
          r
          g
          b
          a
        }
      }
    }
  }
`;

export const PROJECT_EDIT_ANNOTATION_COLOR = gql`
  mutation projectEditAnnotationColor($annotationIds: [ID!]!, $projectId: ID!, $newColor: InputColor!) {
    projectEditAnnotationColor(annotationIds: $annotationIds, projectId: $projectId, newColor: $newColor) {
      id
      state {
        ...ProjectState
      }
    }
  }
`;

export const PROJECT_EDIT_ANNOTATION_CLASS = gql`
  mutation projectEditAnnotationClass($annotationIds: [ID!]!, $projectId: ID!, $newClass: Int!) {
    projectEditAnnotationClass(annotationIds: $annotationIds, projectId: $projectId, newClass: $newClass) {
      id
      state {
        ...ProjectState
      }
      pointClouds {
        id
        availableClasses
      }
    }
  }
`;

export const PROJECT_EDIT_LAYER_ELEVATION = gql`
  mutation projectEditLayerElevation($layerIds: [ID!]!, $projectId: ID!, $newElevation: Float!) {
    projectEditLayerElevation(layerIds: $layerIds, projectId: $projectId, newElevation: $newElevation) {
      id
      state {
        ...ProjectState
      }
    }
  }
`;

export const PROJECT_ADD_GUEST = gql`
  mutation projectAddGuest($projectId: ID!, $email: String!) {
    projectAddGuest(projectId: $projectId, email: $email) {
      id
    }
  }
`;

export const PROJECT_REMOVE_GUEST = gql`
  mutation projectRemoveGuest($projectId: ID!, $userId: ID!) {
    projectRemoveGuest(projectId: $projectId, userId: $userId) {
      id
    }
  }
`;

export const PROJECT_REQUEST_DOWNLOAD_TOKEN = gql`
  query projectRequestDownloadToken($projectId: ID!) {
    projectRequestDownloadToken(projectId: $projectId)
  }
`;
