import { useContext } from 'react';
import { RendererContext } from '../../contexts/RendererContext';
import { Segment } from '../../types/graphqlTypes';
import { keyBy } from 'lodash/fp';

export const usePointClusterProperties = (segments: Segment[]) => {
  const rendererContext = useContext(RendererContext);
  const viewer = rendererContext.viewer;
  const pointCloudsById = keyBy('identifier', viewer?.scene.pointclouds);
  const pointCount = segments
    .map((segment) => pointCloudsById[segment.pointcloudId]?.segmentList[segment.segmentId])
    .reduce((pointCountSum, segment) => {
      return pointCountSum + segment.voxelCount;
    }, 0);

  return { pointCount };
};
