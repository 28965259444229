import { createContext } from 'react';
import { ProjectByIdQuery } from '../types/graphqlTypes';

export type OrthophotoLayersContextType = {
  orthophotoLayers: ProjectByIdQuery['projectById']['state']['orthophotoLayers'];
};

export const OrthophotoLayersContext = createContext<OrthophotoLayersContextType>({
  orthophotoLayers: [],
});
