import { useLazyQuery, useMutation } from '@apollo/client';
import { BlobServiceClient } from '@azure/storage-blob';
import * as zip from '@zip.js/zip.js';
import { useCallback, useContext, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { UserContext } from '../contexts/UserContext';
import { PROJECT_CONFIRM_UPLOAD, REQUEST_UPLOAD_SAS } from '../graphql/project';
import {
  ProjectConfirmPointCloudUploadMutation,
  ProjectConfirmPointCloudUploadMutationVariables,
  ProjectRequestUploadSasQuery,
  ProjectRequestUploadSasQueryVariables,
  useProjectUpdatePreviewImageMutation,
} from '../types/graphqlTypes';

export const getFileNameWithoutExtension = ({ fileName }: { fileName: string }) => {
  return fileName.slice(0, fileName.lastIndexOf('.'));
};

export const renameFile = ({ fileName, newName }: { fileName: string; newName: string }) => {
  return `${newName}.${fileName.slice(fileName.lastIndexOf('.') + 1)}`;
};

export const useUpload = () => {
  const [requestSASToken] = useLazyQuery<ProjectRequestUploadSasQuery, ProjectRequestUploadSasQueryVariables>(
    REQUEST_UPLOAD_SAS,
    { fetchPolicy: 'network-only' },
  );
  const [progress, setProgress] = useState(0);
  const currentUser = useContext(UserContext);
  const { organisationId = '' } = useParams();
  const organisation = currentUser.organisations.find((org) => org.id === organisationId);
  const maxFiles = currentUser.isSuperAdmin ? 10 : organisation?.subscription.simultaneousUploadsLeft;
  const abortControllerRef = useRef<AbortController>();

  const uploadFiles = useCallback(
    async ({
      files,
      projectId,
      withoutId,
      isPythagorasFile,
    }: {
      files: File[];
      projectId: string;
      withoutId?: boolean;
      isPythagorasFile?: boolean;
    }) => {
      abortControllerRef.current = undefined;
      const requestTokenResult = await requestSASToken({ variables: { projectId } });
      const requestUrl = requestTokenResult.data?.projectRequestUploadSAS;
      if (!requestUrl) return [];

      const blobServiceClient = new BlobServiceClient(requestUrl);
      const containerClient = blobServiceClient.getContainerClient('');

      const abortController = new AbortController();
      const abortSignal = abortController.signal;
      abortControllerRef.current = abortController;
      if (isPythagorasFile) {
        const zipFileReader = new zip.BlobReader(files[0]);
        const reader = new zip.ZipReader(zipFileReader);
        const entries = reader.getEntriesGenerator();

        // loop over entries
        // const bytesUploadedPerFile = files.map(() => 0);
        let cloudName = '';
        for await (const fileEntry of entries) {
          if (!cloudName) cloudName = fileEntry.filename.replaceAll('.ppch', '').replaceAll('/', '');
          const blobClient = containerClient.getBlockBlobClient(fileEntry.filename);
          const blobContentType = fileEntry?.filename.slice(fileEntry.filename.indexOf('.') + 1);
          if (!fileEntry || !blobContentType) {
            // bytesUploadedPerFile[index] = entry.uncompressedSize;
            // bytesUploaded = bytesUploadedPerFile.reduce((result, bytes) => result + bytes, 0);
            // setProgress(Math.round((bytesUploaded / bytesToUpload) * 100));
            return;
          }
          try {
            const tsStream = new TransformStream();
            const blobPromise = new Response(tsStream.readable).blob();
            await fileEntry?.getData?.(tsStream.writable);
            const blob = await blobPromise;
            await blobClient.uploadData(blob, {
              concurrency: 10,
              blobHTTPHeaders: { blobContentType },
              abortSignal,
            });
          } catch (e) {
            console.log('upload:: error:', e);
          } finally {
            // bytesUploadedPerFile[index] = entry.uncompressedSize;
            // bytesUploaded = bytesUploadedPerFile.reduce((result, bytes) => result + bytes, 0);
            // setProgress(Math.round((bytesUploaded / bytesToUpload) * 100));
          }
        }

        setProgress(1);

        return [{ name: cloudName }];
      }

      const idsByFileName = files.reduce(
        (result, file) => {
          const fileNameWithoutExtension = getFileNameWithoutExtension({ fileName: file.name });
          if (result[fileNameWithoutExtension]) return result;
          return { ...result, [fileNameWithoutExtension]: uuid() };
        },
        {} as { [key: string]: string },
      );

      let bytesUploaded = 0;
      const bytesToUpload = files.reduce((result, file) => result + file.size, 0);
      const bytesUploadedPerFile = files.map(() => 0);
      return await Promise.all(
        files.slice(0, maxFiles).map(async (file, index) => {
          const fileName = file.name.replace(/[^\p{L}\p{N}.]/gu, '');
          const fileNameWithoutExtension = getFileNameWithoutExtension({ fileName });

          const name = withoutId ? fileName : `${idsByFileName[fileNameWithoutExtension]}___${fileName}`;

          const blobClient = containerClient.getBlockBlobClient(name);
          await blobClient.uploadData(file, {
            concurrency: 10,
            blobHTTPHeaders: { blobContentType: file.type },
            onProgress: (progress) => {
              bytesUploadedPerFile[index] = progress.loadedBytes;
              bytesUploaded = bytesUploadedPerFile.reduce((result, bytes) => result + bytes, 0);
              setProgress(Math.round((bytesUploaded / bytesToUpload) * 100));
            },
            abortSignal,
          });
          return { name };
        }),
      );
    },
    [maxFiles, requestSASToken],
  );

  const resetProgress = useCallback(() => {
    setProgress(0);
  }, []);

  const onCancelUpload = useCallback(() => {
    console.log('cancel?');
    abortControllerRef.current?.abort();
    setProgress(0);
  }, []);

  return [{ progress }, { uploadFiles, resetProgress, onCancelUpload }] as const;
};

export const usePointCloudUpload = () => {
  const upload = useUpload();
  const [confirmUpload] = useMutation<
    ProjectConfirmPointCloudUploadMutation,
    ProjectConfirmPointCloudUploadMutationVariables
  >(PROJECT_CONFIRM_UPLOAD);

  const uploadFiles = useCallback(
    async ({ files, projectId, shouldMerge }: { files: File[]; projectId: string; shouldMerge?: boolean }) => {
      const uploadResults = await upload[1].uploadFiles({ files, projectId });
      await confirmUpload({
        variables: { projectId, fileNames: (uploadResults || []).map((result) => result.name), shouldMerge },
      });
    },
    [confirmUpload, upload],
  );

  return [upload[0], { ...upload[1], uploadFiles }] as const;
};

export const usePreviewUpload = () => {
  const [_, { uploadFiles }] = useUpload();
  const [updatePreviewImage] = useProjectUpdatePreviewImageMutation();

  const setAndUploadPreview = useCallback(
    async (file: File, projectId: string) => {
      const sanitizedFileName = file.name.replace(/[^\p{L}\p{N}.]/gu, '');
      const sanitizedFile = new File([file], sanitizedFileName, { type: file.type });
      await uploadFiles({ files: [sanitizedFile], projectId, withoutId: true });
      await updatePreviewImage({ variables: { projectId, previewImageFileName: sanitizedFileName } });
    },
    [uploadFiles, updatePreviewImage],
  );

  return [setAndUploadPreview];
};
