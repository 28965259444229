import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';

const loadScript = async ({ src, bodyElement }: { src: string; bodyElement: HTMLBodyElement }) => {
  return new Promise((resolve) => {
    let script = undefined;
    if (document.getElementById(src)) return resolve(true);
    script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.async = false;
    script.id = src;
    script.onload = function () {
      resolve(true);
    };
    bodyElement.append(script);
  });
};

const loadStyleSheet = async ({ src, headElement }: { src: string; headElement: HTMLHeadElement }) => {
  return new Promise((resolve) => {
    let ready = false;
    let stylesheet = undefined;
    if (document.getElementById(src)) return resolve(true);
    stylesheet = document.createElement('link');
    stylesheet.type = 'text/css';
    stylesheet.href = src;
    stylesheet.id = src;
    stylesheet.rel = 'stylesheet';
    // @ts-ignore
    stylesheet.onload = stylesheet.onreadystatechange = function () {
      //console.log( this.readyState ); //uncomment this line to see which ready states are called.
      // @ts-ignore
      if (!ready && (!this.readyState || this.readyState === 'complete')) {
        ready = true;
        resolve(true);
      }
    };
    headElement.append(stylesheet);
  });
};

const scripts = [
  'potree/libs/fontawesome/index.js',
  'https://cdn.jsdelivr.net/npm/vue@2.6.12',
  'https://cdnjs.cloudflare.com/ajax/libs/jszip/3.5.0/jszip.min.js',
  'https://unpkg.com/axios/dist/axios.min.js',
  '/potree/libs/jquery/jquery-3.1.1.min.js',
  '/potree/libs/uuid/uuidv4.min.js',
  '/potree/libs/spectrum/spectrum.js',
  '/potree/libs/jquery-ui/jquery-ui.min.js',
  '/potree/libs/other/BinaryHeap.js',
  '/potree/libs/tween/tween.min.js',
  '/potree/libs/d3/d3.js',
  '/potree/libs/proj4/proj4.js',
  '/potree/libs/numeric/numeric.js',
  '/potree/libs/openlayers/ol.js',
  '/potree/libs/i18next/i18next.js',
  '/potree/libs/jstree/jstree.js',
  '/potree/potree/potree.js',
  '/potree/libs/plasio/js/laslaz.js',
  '/potree/libs/other/stats.js',
];

const styleSheets = [
  '/potree/potree/potree.css',
  '/potree/libs/jquery-ui/jquery-ui.min.css',
  '/potree/libs/openlayers/ol.css',
  '/potree/libs/spectrum/spectrum.css',
  '/potree/libs/jstree/themes/mixed/style.css',
];

const loadScripts = async () => {
  const bodyElement = document.getElementsByTagName('body')[0];
  for (const script of scripts) {
    try {
      await loadScript({ src: script, bodyElement });
    } catch (e) {
      console.error('error loading script', script, e);
      Sentry.captureException(e);
    }
  }
};

export const usePotreeSources = () => {
  const [stylesheetsLoaded, setStyleSheetsLoaded] = useState(false);
  const [scriptsLoaded, setScriptsIsLoaded] = useState(false);

  useEffect(() => {
    const headElement = document.getElementsByTagName('head')[0];
    loadScripts().then(() => {
      setScriptsIsLoaded(true);
    });
    Promise.all(styleSheets.map((src) => loadStyleSheet({ src, headElement }))).then(() => {
      setStyleSheetsLoaded(true);
    });
  }, []);

  return stylesheetsLoaded && scriptsLoaded;
};
