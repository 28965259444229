import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { toggleSelectedOrthophotoItem } from '../../redux/rendererReducer';

type onClickOrthophotoItemFn = (args1: { identifier: string }) => void;

export const useSelectedOrthophotoItem = () => {
  const dispatch = useDispatch();

  const onClickOrthophotoItem: onClickOrthophotoItemFn = useCallback(
    (selectedOrthophotoItem) => dispatch(toggleSelectedOrthophotoItem({ ...selectedOrthophotoItem })),
    [dispatch],
  );
  return { onClickOrthophotoItem };
};
