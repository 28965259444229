import { useContext } from 'react';
import { RendererContext } from '../../contexts/RendererContext';

export const useExportDXF = () => {
  const rendererContext = useContext(RendererContext);

  const onExportDXF = (annotationIds?: string[]) => {
    const scene = rendererContext.viewer?.scene;
    if (!scene) return;

    let measurements;
    if (annotationIds?.length) {
      measurements = scene.measurements.filter((measure) => annotationIds.includes(measure.identifier));
    } else {
      // if no annotations are specified, export all
      measurements = [...scene.measurements];
    }
    if (measurements.length) scene.exportDXF(measurements);
  };

  return [onExportDXF];
};
