import { uniqBy } from 'lodash/fp';
import { actionCreator, isType } from './utils/actions';

export const initialRendererState: RendererProviderStore = {
  selectedAnnotations: [],
  selectedCadItems: undefined,
  selectedWmsItem: undefined,
  selectedOrthophotoItem: undefined,
};

type SelectedAnnotation = { identifier: string; type: 'group' | 'annotation'; groupIdentifier?: string };
type SelectedCadItem = { identifier: string; type: 'cadLayer' | 'cadObjectGroup' | 'cadObject' };
type SelectedWmsItem = { identifier: string };
type SelectedOrthophotoItem = { identifier: string };
export interface RendererProviderStore {
  selectedAnnotations: SelectedAnnotation[];
  lastSelectedAnnotation?: SelectedAnnotation;
  selectedPointCloudName?: string;
  mapSelected?: boolean;
  editableNameAnnotation?: {
    id: string;
    name: string;
    type:
      | 'annotation'
      | 'group'
      | 'pointcloud'
      | 'cadObject'
      | 'cadObjectGroup'
      | 'cadLayer'
      | 'wmsLayer'
      | 'orthophotoLayer';
  };
  performanceWarning?: boolean;
  selectedCadItems?: SelectedCadItem[];
  selectedWmsItem?: SelectedWmsItem;
  selectedOrthophotoItem?: SelectedOrthophotoItem;
}

export const toggleSelectedAnnotation = actionCreator<{
  identifier: string;
  type: 'group' | 'annotation';
  clearOtherSelections?: boolean;
  groupIdentifier?: string;
}>('RENDERER_SELECTED_ANNOTATION_TOGGLE');
export const toggleSelectedCadItem = actionCreator<{
  identifier: string;
  type: 'cadLayer' | 'cadObjectGroup' | 'cadObject';
}>('RENDERER_SELECTED_CAD_ITEM');
export const toggleSelectedWmsItem = actionCreator<{
  identifier: string;
}>('RENDERER_SELECTED_WMS_ITEM');
export const toggleSelectedOrthophotoItem = actionCreator<{
  identifier: string;
}>('RENDERER_SELECTED_ORTHOPHOTO_ITEM');
export const mergeSelectedAnnotations = actionCreator<{
  annotations: SelectedAnnotation[];
}>('RENDERER_SELECTED_ANNOTATION_MERGE');
export const selectPointCloud = actionCreator<{ name?: string }>('RENDERER_POINT_CLOUD_SELECT');
export const selectMap = actionCreator('RENDERER_MAP_SELECT');
export const unSelectAll = actionCreator('RENDERER_UN_SELECT_ALL');
export const selectEditableNameAnnotation = actionCreator<
  | {
      id: string;
      name: string;
      type:
        | 'annotation'
        | 'group'
        | 'pointcloud'
        | 'cadObject'
        | 'cadObjectGroup'
        | 'cadLayer'
        | 'wmsLayer'
        | 'orthophotoLayer';
    }
  | undefined
>('RENDERER_SELECT_EDITABLE_NAME_ANNOTATION');
export const updateEditableNameAnnotation = actionCreator<{ name: string }>('RENDERER_UPDATE_EDITABLE_NAME_ANNOTATION');
export const showPerformanceWarning = actionCreator('RENDERER_PERFORMANCE_WARNING');

export type RendererProviderAction =
  | ReturnType<typeof selectPointCloud>
  | ReturnType<typeof selectMap>
  | ReturnType<typeof unSelectAll>
  | ReturnType<typeof toggleSelectedAnnotation>
  | ReturnType<typeof selectEditableNameAnnotation>
  | ReturnType<typeof updateEditableNameAnnotation>
  | ReturnType<typeof showPerformanceWarning>
  | ReturnType<typeof toggleSelectedCadItem>
  | ReturnType<typeof toggleSelectedWmsItem>
  | ReturnType<typeof toggleSelectedOrthophotoItem>;

const rendererReducer = (
  state: RendererProviderStore = initialRendererState,
  action: RendererProviderAction,
): RendererProviderStore => {
  if (isType(action, toggleSelectedAnnotation)) {
    const currentSelectedAnnotations = state.selectedAnnotations;
    const { clearOtherSelections, ...annotation } = action.payload;
    if (action.payload.clearOtherSelections)
      return {
        ...state,
        selectedPointCloudName: undefined,
        selectedAnnotations: [annotation],
        lastSelectedAnnotation: annotation,
        mapSelected: undefined,
        selectedCadItems: [],
        selectedWmsItem: undefined,
        selectedOrthophotoItem: undefined,
      };
    if (currentSelectedAnnotations.find((annotation) => annotation.identifier === action.payload.identifier)) {
      const selectedAnnotations = currentSelectedAnnotations.filter(
        (annotation) => annotation.identifier !== action.payload.identifier,
      );
      return {
        ...state,
        selectedAnnotations,
        lastSelectedAnnotation: selectedAnnotations[selectedAnnotations.length - 1],
      };
    }
    return {
      ...state,
      selectedAnnotations: [...currentSelectedAnnotations, annotation],
      lastSelectedAnnotation: annotation,
    };
  }
  if (isType(action, toggleSelectedCadItem)) {
    const cadItem = action.payload;
    return {
      ...state,
      selectedPointCloudName: undefined,
      selectedAnnotations: [],
      mapSelected: undefined,
      selectedCadItems: [cadItem],
      selectedWmsItem: undefined,
      selectedOrthophotoItem: undefined,
    };
  }
  if (isType(action, toggleSelectedWmsItem)) {
    const wmsItem = action.payload;
    return {
      ...state,
      selectedPointCloudName: undefined,
      selectedAnnotations: [],
      mapSelected: undefined,
      selectedCadItems: [],
      selectedWmsItem: wmsItem,
      selectedOrthophotoItem: undefined,
    };
  }
  if (isType(action, toggleSelectedOrthophotoItem)) {
    const orthophotoItem = action.payload;
    return {
      ...state,
      selectedPointCloudName: undefined,
      selectedAnnotations: [],
      mapSelected: undefined,
      selectedCadItems: [],
      selectedWmsItem: undefined,
      selectedOrthophotoItem: orthophotoItem,
    };
  }
  if (isType(action, mergeSelectedAnnotations)) {
    return {
      ...state,
      selectedAnnotations: uniqBy(
        (annotation) => annotation.identifier,
        [...state.selectedAnnotations, ...action.payload.annotations],
      ),
      lastSelectedAnnotation: action.payload.annotations[action.payload.annotations.length - 1],
    };
  }
  if (isType(action, selectPointCloud)) {
    localStorage.setItem('lastSelectedPointCloudName', action.payload.name || '');
    return {
      ...state,
      selectedPointCloudName: action.payload.name,
      selectedAnnotations: [],
      mapSelected: undefined,
      selectedCadItems: [],
      selectedWmsItem: undefined,
      selectedOrthophotoItem: undefined,
    };
  }
  if (isType(action, selectEditableNameAnnotation)) {
    return {
      ...state,
      editableNameAnnotation: action.payload,
    };
  }
  if (isType(action, updateEditableNameAnnotation)) {
    if (!state.editableNameAnnotation) return state;
    return {
      ...state,
      editableNameAnnotation: { ...state.editableNameAnnotation, name: action.payload.name },
    };
  }
  if (isType(action, unSelectAll)) {
    return initialRendererState;
  }
  if (isType(action, selectMap)) {
    return {
      ...state,
      selectedPointCloudName: undefined,
      selectedAnnotations: [],
      mapSelected: true,
      selectedCadItems: [],
      selectedWmsItem: undefined,
      selectedOrthophotoItem: undefined,
    };
  }
  if (isType(action, showPerformanceWarning)) {
    return {
      ...state,
      performanceWarning: true,
    };
  }

  return state;
};

export default rendererReducer;
