import md5 from 'md5';
import { useContext, useMemo } from 'react';
import { LanguageContext } from '../context/LanguageContext';

const get = (obj: any, path: string, defaultValue: string) => {
  const travel = (regexp: RegExp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
};

const generateKey = (string: string, options: any = {}) => {
  if (options._key) return options._key;

  let context = '';
  if (options._context) {
    context = options._context;
    context = context.replace(/,/g, ':');
  }
  return md5(`5:${string}:${context}`);
};

/**
 * swc = Start with capital
 * cap = Capitalize
 * uppercase = Uppercase
 */
export type useTArgs = {
  args?: { [key: string]: string | number };
  transformer?: (value: string) => string;
  swc?: boolean;
  cap?: boolean;
  uppercase?: boolean;
};
const useT = (e: string, args?: useTArgs) => {
  const { language, languages } = useContext(LanguageContext);

  const hash = generateKey(e);
  const str = get(languages, `${language.toLowerCase()}.${hash}`, e);

  const translationWithArgs = useMemo(() => {
    try {
      let result = Object.keys(args?.args || {}).reduce((p, c) => p.replace(`{${c}}`, (args?.args || {})[c]), str);
      if (args?.swc) result = `${result[0]?.toUpperCase()}${result.slice(1)}`;
      if (args?.cap) result = result.replace(/(?:^|\s|["'([{])+\S/g, (match: string) => match.toUpperCase());
      if (args?.uppercase) result = result.toUpperCase();
      if (args?.transformer) result = args.transformer(result);
      return result;
    } catch (error) {
      console.error('useT::error:', error);
      return '';
    }
  }, [args, str]);
  if (!str) return e;
  return translationWithArgs as string;
};

export default useT;
