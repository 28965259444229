import { useCallback, useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { MeasurementUnits } from '../types/graphqlTypes';

export enum ConvertType {
  NONE,
  DISTANCE,
  AREA,
  VOLUME,
}

const meterToFeetFactor = 3.28084;

const meterToFeet = (value: number, convertType: ConvertType) => {
  if (convertType !== ConvertType.NONE) return value * Math.pow(meterToFeetFactor, convertType);
  return value;
};

const feetToMeter = (value: number, convertType: ConvertType) => {
  if (convertType !== ConvertType.NONE) return value / meterToFeetFactor;
  return value;
};

export const useCondFeetToMeter = () => {
  const { measurementUnit } = useContext(UserContext);

  const condFeetToMeter = useCallback(
    (value: number, convertType: ConvertType = ConvertType.DISTANCE) => {
      if (measurementUnit === MeasurementUnits.Feet) return feetToMeter(value, convertType);
      return value;
    },
    [measurementUnit],
  );

  return { condFeetToMeter };
};

export const useCondMeterToFeet = () => {
  const { measurementUnit } = useContext(UserContext);

  const condMeterToFeet = useCallback(
    (value: number, convertType: ConvertType = ConvertType.DISTANCE) => {
      if (measurementUnit === MeasurementUnits.Feet) return meterToFeet(value, convertType);
      return value;
    },
    [measurementUnit],
  );

  return { condMeterToFeet };
};

export const useFormatNumber = () => {
  const { measurementUnit } = useContext(UserContext);

  const unitChar = (inConvertType: ConvertType) => {
    const measurementMapping = {
      [MeasurementUnits.Meters]: 'm',
      [MeasurementUnits.Feet]: 'ft',
    };
    let outUnit = measurementMapping[measurementUnit];
    switch (inConvertType) {
      case ConvertType.AREA:
        outUnit = outUnit + '²';
        break;
      case ConvertType.VOLUME:
        outUnit = outUnit + '³';
        break;
    }
    return outUnit;
  };
  const formatNumber = (
    value: number,
    options: Intl.NumberFormatOptions & { addUnit?: boolean; convertType?: ConvertType },
  ) => {
    options.convertType = options.convertType || ConvertType.NONE;
    if (measurementUnit === MeasurementUnits.Feet) value = meterToFeet(value, options.convertType);
    let maxDigits = (value > 0.1 && 2) || Math.ceil(-Math.log10(value) + 1);
    if (isNaN(maxDigits)) maxDigits = 2;
    maxDigits = Math.max(Math.min(21, maxDigits), 0);

    let outVal = Intl.NumberFormat('nl-NL', {
      style: 'decimal',
      maximumFractionDigits: maxDigits,
      ...(options || {}),
    }).format(value);
    if (options.addUnit) outVal = outVal + ' ' + unitChar(options.convertType);
    return outVal;
  };

  return { formatNumber };
};
