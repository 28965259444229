import { AddPlaneActionValue, Plane } from '@pointorama/database';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { AddAnnotationAction } from './AddAnnotationAction';

export class AddPlaneAction extends AddAnnotationAction<AddPlaneActionValue> {
  override execute(state: PointCloudCommandManagerState) {
    const annotation = {
      ...this.value,
      type: 'PLANE' as const,
      identifier: this.value.annotationIdentifier,
    } as Plane;
    state.annotations.push(annotation);
    super.execute(state);
  }
}
