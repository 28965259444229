import { memo, useCallback, useContext } from 'react';
import { T, useT } from '../../../translation/src';
import {
  DateFormats,
  Language,
  MeasurementUnits,
  useUserChangeDarkModeMutation,
  useUserChangeDateFormatMutation,
  useUserChangeLanguageMutation,
  useUserChangeUnitsMutation,
} from '../../../types/graphqlTypes';
import { ButtonToggle } from '../../../components/ButtonToggle';
import classNames from 'classnames';
import { useDeviceSize } from '../../../hooks/useDeviceSize';
import { FormInput } from '../../../components/formik/FormInput';
import useOpen from '../../../hooks/useOpen';
import { UserContext } from '../../../contexts/UserContext';
import { CURRENT_USER } from '../../../graphql/user';
import { ResetPasswordModal } from '../ResetPasswordModal';
import { Modal } from '../../../components/Modal';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { FormSelect, FormSelectProps } from '../../../components/formik/FormSelect';
import { Option } from '../../../components/inputs/Select';
import { useLanguageOptions } from '../../../hooks/useLanguageOptions';

const isValidLanguage = (language: string): language is Language => Object.values(Language).some((l) => l === language);

export interface UserSettingsModalProps {
  onClose: () => void;
  open?: boolean;
}

const useTranslations = () => {
  const password = useT('password', { swc: true });
  const language = useT('language', { swc: true });
  return { password, language };
};

const unitButtonToggleOptions = [
  { value: MeasurementUnits.Meters, label: <T _str="Meters" swc /> },
  { value: MeasurementUnits.Feet, label: <T _str="Feet" swc /> },
];

const shortDateFormatButtonToggleOptions = [
  { value: DateFormats.Ddmmyyyy, label: <T _str="D/M/Y" swc /> },
  { value: DateFormats.Mmddyyyy, label: <T _str="M/D/Y" swc /> },
  { value: DateFormats.Yyyymmdd, label: <T _str="Y/M/D" swc /> },
];

const darkModeToggleOptions = [
  { value: true, label: <T _str="Dark mode" swc /> },
  { value: false, label: <T _str="Light mode" swc /> },
];

const $UserSettingsModal: React.FC2<UserSettingsModalProps> = ({ onClose, open }) => {
  const translations = useTranslations();
  const languageOptions = useLanguageOptions();
  const { onClose: closePasswordModal, onOpen: openPasswordModal, open: passwordModalOpen } = useOpen();
  const { measurementUnit, dateFormat, darkMode, language, id: userId } = useContext(UserContext);
  const [changeUserUnits] = useUserChangeUnitsMutation();
  const [changeUserDateFormat] = useUserChangeDateFormatMutation();
  const [changeDarkMode] = useUserChangeDarkModeMutation();
  const [changeLanguage] = useUserChangeLanguageMutation();
  const { xsDevice, mdDevice } = useDeviceSize();

  const onChangeUnit = useCallback(
    async (value: MeasurementUnits) => {
      await changeUserUnits({
        variables: { inUnit: value },
        refetchQueries: [{ query: CURRENT_USER }],
        awaitRefetchQueries: true,
      });
    },
    [changeUserUnits],
  );

  const onChangeDateFormat = useCallback(
    async (value: DateFormats) => {
      await changeUserDateFormat({
        variables: { inFormat: value },
        refetchQueries: [{ query: CURRENT_USER }],
        awaitRefetchQueries: true,
      });
    },
    [changeUserDateFormat],
  );

  const onChangeDarkMode = useCallback(
    async (value: boolean) => {
      await changeDarkMode({
        variables: { inDarkMode: value },
        refetchQueries: [{ query: CURRENT_USER }],
        awaitRefetchQueries: true,
      });

      if (value) {
        localStorage.theme = 'dark';
        document.documentElement.classList.add('dark');
      } else {
        localStorage.theme = 'light';
        document.documentElement.classList.remove('dark');
      }
    },
    [changeDarkMode],
  );

  const onChangeLanguage: FormSelectProps['onChange'] = useCallback(
    async (event) => {
      const language = event.target.value;
      if (!isValidLanguage(language)) return;
      await changeLanguage({
        variables: { language },
        refetchQueries: [{ query: CURRENT_USER }],
        awaitRefetchQueries: true,
        optimisticResponse: {
          userChangeLanguage: {
            __typename: 'User',
            id: userId,
            language,
          },
          __typename: 'Mutation',
        },
      });
    },
    [changeLanguage, userId],
  );

  return (
    <div className="overflow-auto">
      <Modal.Container
        onClose={onClose}
        open={open}
        panelClassName={classNames('w-3/4 overflow-y-auto', mdDevice ? 'h-3/4' : 'h-auto')}
      >
        <Modal.Header>
          <div className="z-50 w-full max-w-full text-xl">
            <div className="flex justify-between">
              <T _str="settings" swc />
              <div className="flex space-x-1 ">
                <XMarkIcon
                  onClick={onClose}
                  className="w-6 h-6 hover:bg-neon-green-300 hover:dark:bg-black hover:dark:text-white"
                />
              </div>
            </div>
          </div>
        </Modal.Header>
        <div
          className={classNames(
            'flex flex-col w-full pb-4 bg-white dark:bg-eerie-black dark:text-white border-gray-300 dark:border-gray-400',
            xsDevice ? 'px-5' : 'px-10',
          )}
        >
          <FormInput
            name="Password"
            disabled
            type="password"
            value="secretsecretsecret"
            label={translations.password}
          />
          <div className="mb-2 text-blue-600 cursor-pointer dark:text-neon-green-400" onClick={openPasswordModal}>
            <T _str="reset password" swc />
          </div>
          <FormSelect
            name="language"
            label={translations.language}
            placeholder={translations.language}
            value={language}
            onChange={onChangeLanguage}
          >
            {languageOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </FormSelect>
          <span className="mb-2 font-semibold">
            <T _str="measurement units" swc />
          </span>
          <div
            className={classNames(
              'flex flex-col p-4 bg-white border border-gray-300 rounded dark:bg-eerie-black w-full',
            )}
          >
            <ButtonToggle options={unitButtonToggleOptions} onChange={onChangeUnit} value={measurementUnit} />
          </div>
          <span className="mt-4 mb-2 font-semibold">
            <T _str="date format" swc />
          </span>
          <div
            className={classNames(
              'flex flex-col p-4 bg-white border border-gray-300 rounded dark:bg-eerie-black w-full',
            )}
          >
            <ButtonToggle
              options={shortDateFormatButtonToggleOptions}
              onChange={onChangeDateFormat}
              value={dateFormat}
            />
          </div>
          <span className="mt-4 mb-2 font-semibold">
            <T _str="theme" swc />
          </span>
          <div
            className={classNames(
              'flex flex-col p-4 bg-white border border-gray-300 rounded dark:bg-eerie-black w-full',
            )}
          >
            <ButtonToggle options={darkModeToggleOptions} onChange={onChangeDarkMode} value={darkMode || false} />
          </div>
        </div>
        <ResetPasswordModal onClose={closePasswordModal} open={passwordModalOpen} />
      </Modal.Container>
    </div>
  );
};

export const UserSettingModal = memo($UserSettingsModal);
