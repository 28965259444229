import { keyBy } from 'lodash';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';

export const getItemsByIdentifier = ({
  state,
  itemIdentifierKey,
}: {
  state: PointCloudCommandManagerState;
  itemIdentifierKey: 'annotations' | 'wmsLayers' | 'orthophotoLayers';
}) => {
  if (itemIdentifierKey === 'annotations') return keyBy(state.annotations, 'identifier');
  else if (itemIdentifierKey === 'wmsLayers') return keyBy(state.wmsLayers || [], 'identifier');
  return keyBy(state.orthophotoLayers || [], 'identifier');
};
