import { useCallback, useContext, useEffect, useState } from 'react';
import { useRerender } from '../useRerender';
import { RendererContext } from '../../contexts/RendererContext';
import { useCondFeetToMeter } from '../useFormatNumber';
import { InputProps } from '../../components/inputs/Input';
import { useCustomSelector } from '../../redux/store';
import { keyBy } from 'lodash/fp';
import { useProjectEditLayerElevationMutation } from '../../types/graphqlTypes';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';

export const useOrthophotoProperties = () => {
  const rendererContext = useContext(RendererContext);
  const viewer = rendererContext.viewer;
  const rerender = useRerender();
  const { selectedOrthophotoItem } = useCustomSelector((state) => state.rendererProvider, ['selectedOrthophotoItem']);
  const { condFeetToMeter } = useCondFeetToMeter();
  const [editLayerElevation] = useProjectEditLayerElevationMutation();
  const { projectId = '' } = useParams();

  const selectedOrthophoto = viewer?.scene.orthophotos.find(
    (orthophoto) => orthophoto.identifier === selectedOrthophotoItem?.identifier,
  );

  const handleInputChange = useCallback(
    debounce((newValue) => {
      if (!selectedOrthophotoItem) return;
      editLayerElevation({
        variables: {
          layerIds: [selectedOrthophotoItem.identifier],
          projectId: projectId,
          newElevation: newValue,
        },
      });
    }, 300),
    [selectedOrthophotoItem, projectId, editLayerElevation],
  );

  const onChangeHeight: Required<InputProps>['onChange'] = useCallback(
    (event) => {
      const newHeight = condFeetToMeter(Number(event.target.value));
      handleInputChange(newHeight);
      selectedOrthophoto?.setElevation(newHeight);
      rerender();
    },
    [rerender, selectedOrthophoto, condFeetToMeter, handleInputChange],
  );

  return [
    {
      height: selectedOrthophoto?.position.z,
    },
    {
      onChangeHeight,
    },
  ] as const;
};

export const useOrthophotosLoaded = () => {
  const rendererContext = useContext(RendererContext);
  const viewer = rendererContext.viewer;

  const orthophotos = viewer?.scene.orthophotos.map((orthophoto) => {
    return { identifier: orthophoto.identifier, isLoaded: orthophoto.initialised };
  });
  const initialOrthophotoLoadedState = keyBy('identifier', orthophotos);

  const [orthophotosLoadedByIdentifier, setOrthophotosLoadedByIdentifier] = useState(initialOrthophotoLoadedState);

  useEffect(() => {
    if (!viewer) return;
    const onOrthophotoInitialised = () => {
      const orthophotos = viewer.scene.orthophotos.map((orthophoto) => {
        return { identifier: orthophoto.identifier, isLoaded: orthophoto.initialised };
      });
      setOrthophotosLoadedByIdentifier(keyBy('identifier', orthophotos));
    };
    viewer.scene.addEventListener('orthophoto_initialised', onOrthophotoInitialised);
    return () => {
      viewer.scene.removeEventListener('orthophoto_initialised', onOrthophotoInitialised);
    };
  }, [viewer]);

  return orthophotosLoadedByIdentifier;
};
