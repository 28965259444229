export function areAlmostEqual(a: number, b: number, epsilon = 1e-10) {
  return Math.abs(a - b) < epsilon;
}

export function VectorsAreAlmostEqual(
  v1: { x: number; y: number; z: number },
  v2: { x: number; y: number; z: number },
  epsilon = 1e-10,
) {
  return (
    areAlmostEqual(v1.x, v2.x, epsilon) && areAlmostEqual(v1.y, v2.y, epsilon) && areAlmostEqual(v1.z, v2.z, epsilon)
  );
}
