import * as Sentry from '@sentry/react';
import { StrictMode, useEffect } from 'react';
import * as ReactDOM from 'react-dom/client';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import { AppContainer } from './App';

if (import.meta.env.PROD)
  Sentry.init({
    dsn: 'https://6e70747785b5401dacda1d4392c9679f@o1342113.ingest.sentry.io/6615889',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: import.meta.env.VITE_NX_SENTRY_ENV,
    enabled: !!import.meta.env.VITE_NX_SENTRY_ENV,
  });

// On page load or when changing themes, best to add inline in `head` to avoid FOUC
if (
  localStorage.theme === 'dark' ||
  (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
) {
  document.documentElement.classList.add('dark');
} else {
  document.documentElement.classList.remove('dark');
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <AppContainer />
  </StrictMode>,
);
