import { DeleteOrthophotoLayerActionValue } from '@pointorama/database';
import { v4 as uuid } from 'uuid';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { IAction } from '../types';
import { removeOrderedIdentifier } from '../helpers/removeOrderedIdentifier';

export class DeleteOrthophotoLayerAction implements IAction<DeleteOrthophotoLayerActionValue> {
  public identifier: string;
  constructor(public value: DeleteOrthophotoLayerActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    const orthophotoLayerIdentifier = this.value.orthophotoLayerIdentifier;
    state.orthophotoLayers = state.orthophotoLayers.filter((layer) => layer.identifier !== orthophotoLayerIdentifier);
    removeOrderedIdentifier(state, {
      identifier: orthophotoLayerIdentifier,
      orderedIdentifiersKey: 'orthophotoLayerOrderedIdentifiers',
    });
  }
  undo() {
    return;
  }
}
