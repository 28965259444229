import { AddOrthophotoLayerActionValue } from '@pointorama/database';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { IAction } from '../types';
import { v4 as uuid } from 'uuid';
import { addOrderedIdentifier } from '../helpers/addOrderedIdentifier';

export class AddOrthophotoLayerAction implements IAction<AddOrthophotoLayerActionValue> {
  public identifier: string;
  constructor(public value: AddOrthophotoLayerActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    const value = { name: this.value.name, identifier: this.value.layerId };
    state.orthophotoLayers = state.orthophotoLayers ? state.orthophotoLayers.concat(value) : [value];
    addOrderedIdentifier(state, {
      identifier: value.identifier,
      itemIdentifierKey: 'orthophotoLayers',
      orderedIdentifiersKey: 'orthophotoLayerOrderedIdentifiers',
    });
  }
  undo() {
    return;
  }
}
