import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { EarthLoader } from '../components/loaders/EarthLoader';
import { Navigation } from '../components/navigation';
import { PotreeSourcesContext } from '../contexts/PotreeSourcesContext';
import { UserContext } from '../contexts/UserContext';
import { useNotifications } from '../hooks/modules/user/useNotifications';
import { usePotreeSources } from '../hooks/usePotreeSources';
import { Language, useCurrentUserQuery } from '../types/graphqlTypes';
import './app.css';

const Notifications: React.FC = () => {
  useNotifications();
  return null;
};

function App() {
  const { data: userData, loading: userDataLoading } = useCurrentUserQuery();
  const userLanguage = userData?.currentUser?.language || Language.En;
  const [lastSelectedOrganisation, setLastSelectedOrganisation] = useLocalStorage('lastSelectedOrganisation', '');
  const [_, setLanguage] = useLocalStorage('lang', userLanguage);
  const potreeSourcesLoaded = usePotreeSources();
  const navigate = useNavigate();
  const location = useLocation();
  const { organisationId, projectId } = useParams();
  useNotifications();

  useEffect(() => {
    if (userDataLoading) return;
    if (!userData?.currentUser) navigate('/login');
  }, [navigate, userData?.currentUser, userDataLoading]);

  useEffect(() => {
    if (!userData?.currentUser) return;
    if (
      location.pathname === '/' ||
      location.pathname === '/organisations' ||
      location.pathname === '/organisations/'
    ) {
      let organisationId = lastSelectedOrganisation;
      const isValidOrganisation =
        organisationId &&
        userData?.currentUser?.organisations.some((organisation) => organisation.id === organisationId);
      if (!isValidOrganisation || !organisationId) organisationId = userData?.currentUser?.organisations[0]?.id;
      if (organisationId) {
        navigate(`/organisations/${organisationId}/projects`);
      } else {
        navigate(`/no-organisation`);
      }
    }
  }, [lastSelectedOrganisation, location.pathname, navigate, userData?.currentUser]);

  useEffect(() => {
    if (organisationId) setLastSelectedOrganisation(organisationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationId]);

  useEffect(() => {
    if (!userData?.currentUser) return;
    if (userData?.currentUser?.darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  });

  useEffect(() => {
    setLanguage(userLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLanguage]);

  if (userDataLoading || !userData?.currentUser)
    return (
      <div className="flex items-center-justify-center">
        <EarthLoader />
      </div>
    );

  return (
    <UserContext.Provider value={userData.currentUser}>
      <PotreeSourcesContext.Provider value={{ isLoaded: potreeSourcesLoaded }}>
        <Notifications />
        <div className="flex w-full h-full overflow-auto">
          {!projectId && <Navigation />}
          <div className="flex flex-grow overflow-auto">
            <Outlet />
          </div>
        </div>
      </PotreeSourcesContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
