import { UpdatePositionWmsLayerActionValue } from '@pointorama/database';
import { v4 as uuid } from 'uuid';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { IAction } from '../types';
import { updateOrderedIdentifiers } from '../helpers/updateOrderedIdentifiers';

export class UpdatePositionWmsLayerAction implements IAction<UpdatePositionWmsLayerActionValue> {
  public identifier: string;
  _previousOrderedIdentifiers: { identifier: string; index: number }[] = [];
  constructor(public value: UpdatePositionWmsLayerActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    this.value.identifiers.forEach(({ identifier }) => {
      const currentOrderedIdentifier = state.wmsLayerOrderedIdentifiers.find((info) => info.identifier === identifier);
      if (!currentOrderedIdentifier) return;
      this._previousOrderedIdentifiers.push({ ...currentOrderedIdentifier });
      updateOrderedIdentifiers(state, {
        ...this.value,
        itemIdentifier: identifier,
        itemIdentifierKey: 'wmsLayers',
        orderedIdentifiersKey: 'wmsLayerOrderedIdentifiers',
      });
    });
  }
  undo(state: PointCloudCommandManagerState) {
    this.value.identifiers.forEach(({ identifier }) => {
      const previousOrderedIdentifier = this._previousOrderedIdentifiers.find((od) => od.identifier === identifier);
      if (!previousOrderedIdentifier) return;
      updateOrderedIdentifiers(state, {
        toIndex: previousOrderedIdentifier.index,
        itemIdentifier: identifier,
        itemIdentifierKey: 'wmsLayers',
        orderedIdentifiersKey: 'wmsLayerOrderedIdentifiers',
      });
    });
    this._previousOrderedIdentifiers = [];
  }
}
