import { MouseEventHandler, memo, useCallback } from 'react';
import { OrthophotoLayer } from '../../../../types/graphqlTypes';
import { useCustomSelector } from '../../../../redux/store';
import { SelectItemContent } from '../../../../components/selects/SelectItem';
import { ArrowDownIcon, ArrowUpIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import LayersIcon from '../../../../assets/icons/layers.svg?react';
import { ContextMenu } from '../../../../components/ContextMenu';
import { T } from '../../../../translation/src';
import { useRename } from '../../../../hooks/modules/renderer/useRename';
import { useExecuteAction } from '../../../../hooks/potree/useExecuteAction';
import { OrthophotoLayersContextType } from '../../../../contexts/OrthophotoLayersContext';
import classNames from 'classnames';
import { useSelectedOrthophotoItem } from '../../../../hooks/potree/useSelectedOrthophotoItem';
import { CircleSpinner } from '../../../../components/Button';
import { useOrthophotosLoaded } from '../../../../hooks/potree/useOrthophotoProperties';
import { AnnotationsIconsWrapper } from '../Annotations/AnnotationsIconsWrapper';

const $OrthophotoLayerComponent: React.FC2<{
  orthophotoLayer: OrthophotoLayer;
  index: number;
  isFirst?: boolean;
  isLast?: boolean;
  loaded?: boolean;
}> = ({ orthophotoLayer, index, isFirst, isLast, loaded }) => {
  const { selectedOrthophotoItem } = useCustomSelector((state) => state.rendererProvider, ['selectedOrthophotoItem']);
  const selected = selectedOrthophotoItem?.identifier === orthophotoLayer.identifier;
  const { onClickOrthophotoItem } = useSelectedOrthophotoItem();
  const onClick: MouseEventHandler<HTMLDivElement> = useCallback(() => {
    onClickOrthophotoItem({ identifier: orthophotoLayer.identifier });
  }, [orthophotoLayer, onClickOrthophotoItem]);
  const visible = orthophotoLayer.visible;
  const [{ onToggleNameEditable }, { nameEditable, nameInputComponent }] = useRename({
    name: orthophotoLayer.name,
    orthophotoLayerIdentifier: orthophotoLayer.identifier,
  });
  const [executeAction] = useExecuteAction();
  const onDeleteOrthophotoLayer = useCallback(() => {
    executeAction({
      action: { orthophotoLayerIdentifier: orthophotoLayer.identifier },
      type: 'DELETE_ORTHOPHOTO_LAYER',
    });
  }, [executeAction, orthophotoLayer.identifier]);

  const onMoveUp = useCallback(
    () =>
      executeAction({
        type: 'UPDATE_POSITION_ORTHOPHOTO_LAYER',
        action: {
          toIndex: index - 1,
          identifiers: [{ identifier: orthophotoLayer.identifier }],
        },
      }),
    [executeAction, index, orthophotoLayer.identifier],
  );
  const onMoveDown = useCallback(
    () =>
      executeAction({
        type: 'UPDATE_POSITION_ORTHOPHOTO_LAYER',
        action: {
          toIndex: index + 1,
          identifiers: [{ identifier: orthophotoLayer.identifier }],
        },
      }),
    [executeAction, index, orthophotoLayer.identifier],
  );

  if (nameEditable) return nameInputComponent || null;
  return (
    <ContextMenu
      buttonChild={
        <SelectItemContent
          onClick={onClick}
          icon={LayersIcon}
          active={selected}
          activeLevel={0}
          selectStyle="primary"
          idle={!visible}
          className={'relative pl-8'}
          onDoubleClick={onToggleNameEditable}
        >
          <div className="overflow-hidden">{orthophotoLayer.name}</div>
          {loaded && (
            <div className="flex justify-end flex-grow">
              <AnnotationsIconsWrapper
                identifier={orthophotoLayer.identifier}
                visible={visible}
                orthophoto={orthophotoLayer}
              />
            </div>
          )}
          {!loaded && (
            <div className="flex items-center justify-center w-6 h-6 ml-auto" title="Progress">
              <CircleSpinner className="w-4 h-4 text-neon-green-300" />
            </div>
          )}
        </SelectItemContent>
      }
      panelClassName="divide-y"
    >
      <div className="flex flex-col">
        <SelectItemContent icon={PencilIcon} onClick={onToggleNameEditable}>
          <T _str="rename" swc />
        </SelectItemContent>
      </div>
      <div>
        <SelectItemContent icon={ArrowUpIcon} onClick={onMoveUp} disabled={isFirst}>
          <T _str="move up" swc />
        </SelectItemContent>
        <SelectItemContent icon={ArrowDownIcon} onClick={onMoveDown} disabled={isLast}>
          <T _str="move down" swc />
        </SelectItemContent>
      </div>
      <div className="flex flex-col">
        <SelectItemContent icon={TrashIcon} onClick={onDeleteOrthophotoLayer}>
          <T _str="delete" swc />
        </SelectItemContent>
      </div>
    </ContextMenu>
  );
};
export const OrthophotoLayerComponent = memo($OrthophotoLayerComponent);

interface OrthophotoLayersProps {
  className?: string;
  orthophotoLayers: OrthophotoLayersContextType['orthophotoLayers'];
}

const $OrthophotoLayers: React.FC2<OrthophotoLayersProps> = ({ className, orthophotoLayers }) => {
  const orthophotosLoadedByIdentifier = useOrthophotosLoaded();
  return (
    <div className={classNames(className, 'py-2')}>
      {orthophotoLayers?.map((orthophotoLayer, index) => {
        return (
          <OrthophotoLayerComponent
            orthophotoLayer={orthophotoLayer}
            key={orthophotoLayer.identifier}
            index={index}
            isFirst={index === 0}
            isLast={index === orthophotoLayers.length - 1}
            loaded={orthophotosLoadedByIdentifier[orthophotoLayer.identifier]?.isLoaded}
          />
        );
      })}
    </div>
  );
};
export const OrthophotoLayers = memo($OrthophotoLayers);
