import { EditPlaneActionValue, Position } from '@pointorama/database';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { IAction } from '../types';
import { v4 as uuid } from 'uuid';

export class EditPlaneAction implements IAction<EditPlaneActionValue> {
  public identifier: string;
  _previousValues?: {
    position: Position;
    normal: Position;
  };

  constructor(public value: EditPlaneActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    const annotation = state.annotations.find(
      (annotation) => annotation.identifier === this.value.annotationIdentifier,
    );
    if (!annotation || annotation.type !== 'PLANE') return;
    this._previousValues = {
      position: annotation.position,
      normal: annotation.normal,
    };
    const { position, normal } = this.value;
    annotation.position = position;
    annotation.normal = normal;

    return annotation;
  }
  undo(state: PointCloudCommandManagerState) {
    const annotation = state.annotations.find(
      (annotation) => annotation.identifier === this.value.annotationIdentifier,
    );
    if (!annotation || annotation.type !== 'PLANE' || !this._previousValues) {
      this._previousValues = undefined;
      return;
    }
    return (annotation.position = this._previousValues.position) && (annotation.normal = this._previousValues.normal);
  }
}
